import { Player, Source, } from "@castify-inc/castify-player"
import PlayerWorker from "@castify-inc/castify-player/worker?worker"
import React, { ReactElement, useEffect, useReducer, useRef, useState } from "react"
import { app } from "~context/core"
import { OTT } from "~core/castify/api"
import { Box, HBox, VBox } from "~parts/box"
import { toHoursString } from "~util/format"
import { Seeker } from "./seeker"
import { useQuery } from "~parts/paged"

type Origin = {
  time: number | "live"
  duration?: number
}

type State = {
  source?: Source
  from?: Origin
  wait: boolean
  time: number
  paused: boolean
  silent: boolean
}

const spinnerStyle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: "auto",
}

const useWebWorkers = true
if (useWebWorkers) {
  Player.createWorker = () => new PlayerWorker
}

const debug = true

type InlinePlayerProps = {
  broadcastId?: string
  ott?: OTT
  width?: number | string
  height?: number | string
}

type Action
= { tag: "toggle", name: "silent" | "paused" }
| { tag: "load", use?: Source }
| { tag: "play", from: Origin }
| { tag: "seek", time: number }
| { tag: "time", with: number }
| { tag: "wait", with: boolean }

function reducer(old: State, src: Action): State {
  switch (src.tag) {
    case "toggle": {
      return { ... old, [src.name]: !old[src.name] }
    }
    case "seek": {
      if (old.from?.time === "live") {
        return { ... old, time: src.time, from: { time: src.time, duration: old.time } }
      }
      else {
        return { ... old, time: src.time, from: { time: src.time, duration: old.from?.duration } }
      }
    }
    case "load": {
      return { ... old, source: src.use, from: undefined }
    }
    case "play": {
      return { ... old, from: src.from }
    }
    default: {
      return { ... old, [src.tag]: src.with }
    }
  }
}

export function InlinePlayer(props: InlinePlayerProps): ReactElement {

  const video = useRef<HTMLVideoElement>(null)

  const player = useRef<Player>()

  const [hover, setHover] = useState(false)

  const [state, change] = useReducer(reducer, {
    wait: false,
    time: 0,
    paused: false,
    silent: true
  })

  const query = useQuery()
  const rateString = query.get("rate")

  useEffect(() => {
    if (video.current === null) {
      player.current = undefined
      return
    }
    const obj = new Player(video.current, { debug, useWebWorkers })
    if (rateString) {
      obj.rate = parseFloat(rateString)
    }
    function onTimer() {
      change({
        tag: "time",
        with: obj.time ?? 0
      })
    }
    function onState() {
      change({
        tag: "wait",
        with: obj.state === "loading"
      })
    }
    obj.addEventListener("timer", onTimer)
    obj.addEventListener("state", onState)
    if (debug) {
      obj.addEventListener("ended", console.log)
    }
    player.current = obj
    return () => {
      obj.removeEventListener("timer", onTimer)
      obj.removeEventListener("state", onState)
      obj.reset()
    }
  }, [video.current])

  useEffect(() => {
    change({ tag: "load", use: toSource(props) })
  }, [props.broadcastId, props.ott])

  useEffect(() => {
    if (player.current) {
      player.current.paused = state.paused
    }
  }, [player.current, state.paused])

  useEffect(() => {
    const { source } = state
    if (player.current) {
      player.current.source = source
      player.current.source?.load().then(({ stoppedAt, playable }) => {
        if (player.current?.source === source) {
          const e = playable?.[playable.length - 1]
          if (stoppedAt && e?.duration !== undefined) {
            change({ tag: "play", from: { time: 0, duration: e.start + e.duration } })
          }
          else {
            change({ tag: "play", from: { time: "live" } })
          }
        }
      })
    }
  }, [player.current, state.source])

  useEffect(() => {
    if (player.current) {
      if (state.from) {
        player.current.seek(state.from.time)
      }
      else {
        player.current.reset()
      }
    }
  }, [player.current, state.from])

  function getPreview(time: number): Promise<Blob> {
    if (!state.source?.broadcastId) {
      return Promise.reject()
    }
    return app.api.getPreview(state.source.broadcastId, "console", Math.floor(time))
  }

  return (
    <div
      style={{
        display: "inline-block",
        width: props.width,
        height: props.height,
        background: "black"
      }}
      onMouseEnter={() => setHover( true)}
      onMouseLeave={() => setHover(false)}
    >
      <div style={{ display: "flex", height: "100%" }}>
        <video ref={video} muted={state.silent} width="100%" height="auto" controls={false} playsInline={true} />
      </div>
      <div style={spinnerStyle} hidden={!state.wait} className="position-absolute spinner-border text-primary" />
      <div hidden={!hover} style={{
        background: "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)",
        margin: "auto 0 0 0",
        position: "absolute",
        right: 0, bottom: 0, left: 0
      }}>
        <VBox>
          <Seeker
            onChange={time => change({ tag: "seek", time })}
            getPreview={getPreview}
            value={state.from?.duration ? state.time / state.from.duration: 1}
            width="100%"
            height={12}
            duration={state.from?.duration ?? state.time}
          />
          <HBox alignItems="center">
            <button onClick={() => change({ tag: "toggle", name: "paused" })} type="button" className="btn btn-sm text-light">
              <i className={ state.paused ? "fas fa-play" : "fas fa-pause" } />
            </button>
            <button onClick={() => change({ tag: "toggle", name: "silent" })} type="button" className="btn btn-sm text-light">
              <i className={ state.silent ? "fas fa-volume-mute" : "fas fa-volume-up" } />
            </button>
            <Box
              color="white"
              fontSize="8pt"
              fontFamily="monospace"
              margin="0 8px 0 auto"
            >
              { state.from && (
                state.from.duration
                  ? `${toHoursString(state.time)} / ${toHoursString(state.from.duration)}`
                  : `${toHoursString(state.time)}`
              )}
            </Box>
          </HBox>
        </VBox>
      </div>
    </div>
  )
}

function toSource(props: InlinePlayerProps): Source | undefined {
  if (props.broadcastId) {
    return new Source(props.broadcastId, {
      ... props.ott && { _raw: { query: { ... props.ott, no_auth: "1" } } }
    })
  }
}
